import { Switch, useHistory } from "react-router-dom"
import { buildRouteAsJSX, paylinkRoutes } from "models/routes"
import { useProducts } from "hooks/useProducts"
import { useEffect } from "react"
import { useLastProduct, Product } from "pages/Main"

export const PaylinkMain = () => {
  const { loaded, hasPaylink } = useProducts()
  const history = useHistory()
  const [ _, setLastProduct ] = useLastProduct()
  
  useEffect(() => {
    if (loaded) {
      if (!hasPaylink) history.push('/')
      else setLastProduct(Product.PAYLINK)
    }
  }, [loaded, hasPaylink, setLastProduct])

  if (!hasPaylink) return <></>
  return <>
    <Switch>
      {paylinkRoutes.map(route => buildRouteAsJSX(route))}
    </Switch>
  </>
}