import React, { useMemo } from 'react'
import { MaskedInput } from 'antd-mask-input'

export const ValidPhoneNumber = {validator: (_, input: string) => {
  const withoutCountryCode = (input: string) => {
    const [country, ...rest] = input.split(' ')
    return rest.join(' ')
  }
  const inputWithoutCountryCode = input.startsWith('+') ? withoutCountryCode(input) : input
  return [...inputWithoutCountryCode].filter(c => c.match(/[0-9]/)).length >= 10 ? Promise.resolve() : Promise.reject();
}, message: 'Phone numbers must have at least 10 digits, excluding country codes'}

export const PhoneInput: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const mask = useMemo(() => {
    return [
      {
        mask: 'COUNTRY_CODE NATIONAL EXTENSION_AFFIX DIGITS',
        lazy: true,
        blocks: {
          COUNTRY_CODE: {mask: '+1'},
          NATIONAL: {mask: '(000) 000-0000'},
          EXTENSION_AFFIX: {mask: 'Ext.'},
          DIGITS: {mask: /[0-9]+/}
        }
      }
    ];
  }, [])

  return <MaskedInput
    {...props}
    placeholder='+1 (800) 867-5309'
    mask={mask}
    onPaste={(event) => {
      /*
      TODO
      If paste event becomes properly handled by antd-mask-input, this may need to be reverted
      Link to issue: https://github.com/antoniopresto/antd-mask-input/issues/55
      */
      event.preventDefault()
    }}
  />
}