import { buildRouteAsJSX, mainRoutes } from "models/routes"
import { useLastProduct, Product } from "pages/Main"
import { useEffect } from "react"
import { Switch } from "react-router-dom"

export const SurchargingMain = () => {
  const [ _, setLastProduct ] = useLastProduct()

  useEffect(() => {
    setLastProduct(Product.SURCHARGING)
  }, [setLastProduct])

  return <Switch>
    {mainRoutes.map(route => buildRouteAsJSX(route))}
  </Switch>
}