import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { IFrameLayout, SessionRequest } from "models/paylink"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

type SessionConfigProps = {
  gatewayId: string,
  integrated: boolean,
}

const getSessionConfig = (props: SessionConfigProps): SessionRequest => {
  return {
    session: {
      gatewayId: props.gatewayId,
      dropIn: {
        appearance: {
          styles: {
            "--body-background-color": "transparent",
            "--tabs-content-background-color": "transparent",
          }
        },
        card: {},
        container: '.IFrameContainer',
        // bankAccount: {},
        ...(props.integrated ? {
          button: {
            text: 'Pay'
          }
        } : {}),
        layout: {
          collapsed: false,
          layoutType: IFrameLayout.Tabs,
          radios: false,
          spaced: false,
        }
      },
      surcharge: {
        amount: {
            value: "100"
        },
        processor: "default"
      }
    }  
  }
}

export const PaylinkIFrame = ({ merchantId, gatewayId, submit, onTokenReceived }: {merchantId: string, gatewayId: string, submit: boolean, onTokenReceived: (data: any) => void}) => {
  const { getOrigin, createSession, getSessionUrl } = usePaylinkAPI()

  const [ iFrameSource, setIFrameSource ] = useState<any>(undefined)
  const [ iFrameHeight, setIFrameHeight ] = useState<number>(0)

  const iFrameSessionId = useMemo(() => iFrameSource?.session?.id, [iFrameSource])
  const iFrameUrl = useMemo(() => !!iFrameSessionId ? getSessionUrl(iFrameSessionId) : '', [iFrameSessionId])

  const iFrameRef = useRef(null)

  const handlePaymentMethodReceived = useCallback((data: any) => {
    onTokenReceived(data)
  }, [])

  useEffect(() => {
    createSession(merchantId, getSessionConfig({ gatewayId, integrated: false }))
      .then(source => setIFrameSource(source))
  }, [getSessionConfig, gatewayId])

  useEffect(() => {
    if (!submit) return
    if (!iFrameRef.current) return
    (iFrameRef.current as HTMLIFrameElement).contentWindow!.postMessage({ type: 5 }, getOrigin())
  }, [submit])

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.origin !== getOrigin()) return
    const message = event.data
    const { data } = message

    if (message.type === 2) setIFrameHeight(data.height)
    else if (message.type === 4) handlePaymentMethodReceived(data)
    else console.log('iFrame message', data)
  }, [handlePaymentMethodReceived])

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return <>
    {!!iFrameSource && <>
      <iframe height={iFrameHeight} width='100%' src={iFrameUrl} frameBorder={0} ref={iFrameRef} />
    </>}
  </>  
}