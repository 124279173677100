/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import { ReactNode, useCallback, useRef } from 'react'
import _ from 'lodash'
import { Button, Input, InputRef, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FilterDropdownProps, Key } from 'antd/es/table/interface'

export const useTableSearch = <T extends unknown>() => {
  const searchInput = useRef<InputRef>()
  const getSearchProps = useCallback((onFilter: (value: boolean | Key, record: T) => boolean) => {
    return {
      filterDropdown: (props: FilterDropdownProps): ReactNode => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props
        return <div style={{ padding: 8 }}>
          <Input
            ref={node => !!node && (searchInput.current = node)}
            placeholder={`Search`}
            value={selectedKeys[0]?.toString()}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={e => {
              e.stopPropagation() // don't allow enter to bubble through to sorter
              confirm()
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      },
      filterIcon: (filtered: boolean): ReactNode => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (v: boolean | Key, r: T) => onFilter(v.toString(), r), // type narrowing for overly generic antd typing v: (string | number | boolean)
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible && !!searchInput) {
          setTimeout(() => !!searchInput.current && searchInput.current.select(), 100)
        }
      }
    }
  }, [])

  return { getSearchProps }
}