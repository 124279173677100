import { useCallback } from "react"
import { ContentType, useRequest } from "./useRequest"
import _ from "lodash";
import { AcquirerReport, arrayToUrlParams, formatURIDate } from "models/models";
import { Dayjs } from "dayjs";
import qs from "qs";
import { FilterProps } from "utils/txlogHelper";

// Support API

export const useSupportAPI = () => {
  const { makeRequest } = useRequest()

  const objectToQueryString = (input: { [k: string]: any }): string => {
    return Object.entries(input)
      .filter(([_, v]) => !!v)
      .map(([k, v]) => [encodeURIComponent(k), encodeURIComponent(v)])
      .map(([k, v]) => `${k}=${v}`)
      .join("&")
  }

  const getInterchangeProgramData = useCallback(
    (mid: string | undefined) => {
      return mid ?
        makeRequest([`/v1/support/processor/interchange/programs?mid=${mid}`, {}])
        : makeRequest([`/v1/support/processor/interchange/programs`, {}])
    }, [])

  const createProcessor = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/support/merchant/${mid}/processor/sf`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const getProcessorCodes = useCallback(
    (codeTypes: Array<string>) => {
      return makeRequest([`/v1/support/processor/codes?${arrayToUrlParams('codeTypes', codeTypes)}`, {}], {mockValue: () => [{naics: []}, {mcc: []}, {sic: []}]})
    }, [])


  const getMicrosoftOAuthLogin = useCallback(
    (comeback: string) => {
      return makeRequest([`/v1/support/ms/login?comeback=${comeback}`,
          {
              method: "POST",
              body: JSON.stringify({}),
              headers: {
                  "content-type": "application/json"
              }
          }])
    }, []
  )

  const workerMessage = useCallback(
    (msg: string, params: Map<string, string | undefined>, responseType: ContentType = ContentType.JSON) => {
      const qs1 = Array.from(params.keys())
        .filter(([_, v]) => !!v)
        .map(k => [encodeURIComponent(k), encodeURIComponent(params.get(k)!)])
        .map(([k, v]) => `${k}=${v}`)
        .join(";")
      let paramString = ""
      if (!_.isEmpty(qs1)) {
        paramString = `?params=${qs1}`
      }
      return makeRequest([`/v1/support/worker/${msg}${paramString}`, {}], { responseType })
    }, [])

  const queryCommonBin = useCallback(
    (params: Map<string, string | undefined>) => {
      const qs1 = Array.from(params.keys())
        .filter(([_, v]) => !!v)
        .map(k => [encodeURIComponent(k), encodeURIComponent(params.get(k)!)])
        .map(([k, v]) => `${k}=${v}`)
        .join("&")
      let paramString = ""
      if (!_.isEmpty(qs1)) {
        paramString = `?${qs1}`
      }
      return makeRequest([`/v1/support/bin/common${paramString}`, {}])
    }, [])

  const setNotificationOn = useCallback(
    (mid: string, date: Dayjs) => {
      return makeRequest([
        `/v1/support/merchant/${mid}/notification`,
        {
          method: "POST",
          body: JSON.stringify({
            notificationOn: formatURIDate(date)
          }),
          headers: {
            "content-type": "application/json"
          }
        }
      ], { responseType: ContentType.NONE })
    }, []
  )

  const unsetNotification = useCallback(
    (mid: string) => {
      return makeRequest([
        `/v1/support/merchant/${mid}/notification/unset`,
        {
          method: 'POST',
          body: JSON.stringify({}),
          headers: {
            'content-type': 'application/json'
          }
        }
      ], { responseType: ContentType.NONE })
    }, []
  )

  const initiateSse = useCallback(
    (uuid: string, pq: string = "n") => {
      return makeRequest([
        `/v1/support/user/notifications?ct=${uuid}&pq=${pq || "n"}`,
        {
          method: "POST",
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" }
        }
      ])
    }, []
  )

  const validateSse = useCallback(
    (t: string) => {
      return makeRequest([
        `/v1/support/user/notifications?v=true&t=${t}`,
        {
          method: "POST",
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" }
        }
      ])
    }, []
  )

  const getStageCommonBin = useCallback(
    (source: string, data: any, original: boolean = false) => {
      console.log("getSupportLoadToCommon", objectToQueryString(data))
      console.log('original: ', original)
      const url = original ? `/v1/support/bin/stage?source=${source}&${objectToQueryString(data)}` : 
        `/v1/support/bin/stage/job?source=${source}&${objectToQueryString(data)}` 
      return makeRequest([
        url, {
          method: "GET"
        }
      ])
    }, []
  )

  const stageCommonBin = useCallback(
    (source: string, bd: string | undefined, bins: Array<string> | undefined) => {
      const body = JSON.stringify({ source, bd, bins })
      console.log('body: ', body)
      return makeRequest([
        `/v1/support/bin/stage?source=${source}`, {
          method: "POST",
          body: body,
          headers: { "content-type": "application/json" }
        }
      ], { responseType: ContentType.NONE })
    }, []
  )

  const materializeCommonBin = useCallback(
    (source: string, bins: Array<string>) => {
      const body = JSON.stringify({ source, bins })
      return makeRequest([
        `/v1/support/bin/materialize`, {
          method: "POST",
          body: body,
          headers: { "content-type": "application/json" }
        }
      ], { responseType: ContentType.NONE })
    }, []
  )

  const materializeBins = useCallback(
    () => {
      return makeRequest([`/v1/support/bin/materialize`, {
        method: 'GET'
      }])
    }, []
  )

  const binLoadInfo = useCallback(
    () => {
      return makeRequest([`/v1/support/bin/load/info`, {
        method: 'GET'
      }])
    }, []
  )

  const supportBinAction = useCallback(
    (type: string, source: string, version: string) => {
      return makeRequest([`/v1/support/bin/${type}?source=${source}&version=${version}`, {
        method: "GET"
      }])
    }, []
  )

  const supportBinActionJob = useCallback(
    (
      jobName: string,
      source: string,
      page?: string,
      pageSize?: string,
      pageLimit?: string,
      filter?: string,
      cacheSource?: boolean,
      sourceUrl?: string,
      version?: string,
      supportNote?: string,
      original?: boolean
    ) => {
      const queryParams = new URLSearchParams({
        source,
        ...(page !== undefined && { page }),
        ...(pageSize !== undefined && { pageSize }),
        ...(pageLimit !== undefined && { pageLimit }),
        ...(filter !== undefined && { filter }),
        ...(cacheSource !== undefined ? { cacheSource: cacheSource.toString() } : {}),
        ...(sourceUrl !== undefined && { sourceUrl }),
        ...(supportNote !== undefined && { supportNote }),
        ...(version !== undefined && { version }), // Include version only if defined
      });
  
      const endpoint = `/v1/support/bin/${jobName}${original && original === true ? '' : '/job'}?${queryParams.toString()}`;
  
      return makeRequest([endpoint, { method: "GET" }]);
    },
    []
  )
  
    const binDiff = useCallback(
        (source: string, left: string, right: string, original: boolean) => {
            return makeRequest([
                `/v1/support/bin/diff${original && original === true ? '' : '/job'}?source=${source}&left=${left}&right=${right}`
            ], { responseType: ContentType.NONE })
        }, []
    )

  const binDiffResults = useCallback(
    (source: string, left: string, right: string) => {
      return makeRequest([
        `/v1/support/bin/diff/results?source=${source}&left=${left}&right=${right}`, {
          method: "GET",
          cache: "no-store"
        }], { responseType: ContentType.JSON })
    }, [])

  const getParquetFiles = useCallback(
    (source: string) => {
      return makeRequest([`/v1/support/bin/parquet?source=${source}`, {
        method: "GET",
        cache: "no-store"
      }], { responseType: ContentType.JSON })
    }, [])

  const binDiffCSV = useCallback(
    (source: string, left: string, right: string) => {
      return makeRequest([
        `/v1/support/bin/diff/csv?source=${source}&left=${left}&right=${right}`, {
          method: 'GET',
          cache: 'no-store'
        }], { responseType: ContentType.CSV })
    }, []
  )

  const getAcquirerReport = useCallback(
    (cids: string[] | undefined, mids: string[] | undefined): Promise<AcquirerReport> => {
      const params = {...(!!cids ? {'cids': cids} : {}), ...(!!mids ? {'mids': mids} : {})}
      const paramString = qs.stringify(params, {'indices': false})
      return makeRequest([
        `/v1/support/acquirer/report?${paramString}`
      ])
    }, [])

  const getJurisdictionMaxRate = useCallback(
    (jurisdiction: string): Promise<{rate: number}> => {
      return makeRequest([`/v1/support/jurisdiction/${jurisdiction}`])
    }, [])

  const getAPIHealthCheck = useCallback(
    (): Promise<{ping: string}> => {
      return makeRequest([`/healthy`])
    }, [])

  const getTxLogs = useCallback(
    (attributes: FilterProps & {skip?: any, limit?: any}): Promise<{totalRecords: number, data: any[]}> => {
      const params = qs.stringify(attributes, {arrayFormat: 'repeat'})
      const responseType = attributes.format === 'csv' || attributes.format === 'csv-remote' ? ContentType.TEXT : ContentType.JSON
      return makeRequest([`/v1/support/txlog?${params}`, {}], { responseType })
    }, [])  

  return { getInterchangeProgramData, workerMessage, setNotificationOn, unsetNotification,
      initiateSse, validateSse, getMicrosoftOAuthLogin, createProcessor, getProcessorCodes, queryCommonBin,
      stageCommonBin, getStageCommonBin, supportBinAction, materializeBins, materializeCommonBin,
      binLoadInfo, binDiff, binDiffResults, getParquetFiles, binDiffCSV, getAcquirerReport, supportBinActionJob, getJurisdictionMaxRate,
      getAPIHealthCheck, getTxLogs }
}
