import { Tag } from "antd"

enum UserRole {
  VIEWER = 'VIEWER',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',

  PAY_LINK_PAYER = 'PAY_LINK_PAYER',
  PAY_LINK_ACCOUNTING = 'PAY_LINK_ACCOUNTING',
  PAY_LINK_ADMIN = 'PAY_LINK_ADMIN'
}

const UserRoleData = {
  [UserRole.VIEWER]: {
    color: 'green',
    label: 'Viewer'
  },
  [UserRole.MANAGER]: {
    color: 'blue',
    label: 'Manager'
  },
  [UserRole.OWNER]: {
    color: 'default',
    label: 'Owner'
  },
  [UserRole.ADMIN]: {
    color: 'red',
    label: 'Admin'
  },

  [UserRole.PAY_LINK_PAYER]: {
    color: 'green',
    label: 'PayLink Payer'
  },
  [UserRole.PAY_LINK_ACCOUNTING]: {
    color: 'blue',
    label: 'PayLink Accounting'
  },
  [UserRole.PAY_LINK_ADMIN]: {
    color: 'default',
    label: 'PayLink Admin'
  },
}

export const RoleTag = ({ role }: {role: string | undefined}) => {
  if (role === undefined || typeof role !== 'string') return <></>
  const roleAsEnum = role.toUpperCase() as UserRole
  if (UserRoleData[roleAsEnum] === undefined) return <></>
  const roleData = UserRoleData[roleAsEnum]
  return <Tag color={roleData.color}>
    {roleData.label}
  </Tag>
}