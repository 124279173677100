import { Button, Form, Input, Space } from "antd"
import { useForm } from "antd/es/form/Form"
import { PhoneInput } from "components/PhoneInput"
import { formLayout, tailLayout } from "models/models"
import { CustomerRequest } from "models/paylink"
import { useCallback } from "react"

const isRequired = (message?: string) => !!message ? {required: true, message} : {required: true}

export const CustomerForm = ({ onSubmit, onCancel, initialValues }: {onSubmit: (request: CustomerRequest) => void, onCancel: () => void, initialValues?: CustomerRequest}) => {
  const [ form ] = useForm<CustomerRequest>()

  const handleSubmit = useCallback((values: CustomerRequest) => {
    return onSubmit({...values, phoneNumber: values.phoneNumber.replace(/\D/g, "")})
  }, [onSubmit])

  return <Form form={form} {...formLayout} onFinish={handleSubmit} initialValues={initialValues}>
    <Form.Item label='First Name' name='firstName' rules={[isRequired('Please enter a first name')]}>
      <Input placeholder="John" />
    </Form.Item>

    <Form.Item label='Last Name' name='lastName' rules={[isRequired('Please enter a last name')]}>
      <Input placeholder="Doe" />
    </Form.Item>

    <Form.Item label='Company Name' name='companyName' rules={[isRequired('Please enter a company name')]}>
      <Input  />
    </Form.Item>

    <Form.Item label='Email' name='email' rules={[isRequired('Please enter an email address'), {type: 'email', message: 'Please enter a valid email address'}]}>
      <Input placeholder="john@doe.com" />
    </Form.Item>

    <Form.Item label='Phone Number' name='phoneNumber' rules={[isRequired('Please enter a phone number')]}>
      <PhoneInput />
    </Form.Item>

    <Form.Item label='Merchant Account Number' name='merchantAccountNumber' rules={[isRequired('Please enter a merchant account number')]}>
      <Input />
    </Form.Item>

    <Form.Item {...tailLayout}>
      <Space size='small'>
        <Button htmlType='submit' type='primary'>Submit</Button>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </Space>
    </Form.Item>
  </Form>
}
