import { blue } from "@ant-design/colors"
import { BookOutlined, ContactsTwoTone, CreditCardTwoTone, DollarOutlined, HomeTwoTone, PlusCircleTwoTone } from "@ant-design/icons"
import { Button, Card, Cascader, Col, Empty, Form, Input, InputNumber, Modal, notification, Radio, Result, Row, Select, SelectProps, Spin, Switch, Table, Tag, Tooltip, Typography } from "antd"
import { v4 } from 'uuid'
import { CardProps } from "antd/lib"
import { PaylinkIFrame } from "components/paylink/PaylinkIFrame"
import { commaSeparatedFormatter, commaSeparatedParser } from "components/Processor/ProcessorCore"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { useAuthorization } from "hooks/useAuthorization"
import { usePortalResources } from "hooks/usePortalResources"
import { getMerchantDisplayName, Jurisdiction, Merchant, regionsByJurisdiction, UserRole } from "models/models"
import { Address, CardMethod, Customer, Gateway, PaymentType, TransactionFeeResponse } from "models/paylink"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "antd/es/form/Form"
import useFormInstance from "antd/es/form/hooks/useFormInstance"
import useMessage from "antd/es/message/useMessage"
import { CustomTagProps } from "rc-select/lib/BaseSelect"
import { PhoneInput } from "components/PhoneInput"

const { Text, Paragraph } = Typography

type NewCustomer = 'newCustomer'
const NewCustomer: NewCustomer = 'newCustomer'

type NewPaymentMethod = 'newPaymentMethod'
const NewPaymentMethod: NewPaymentMethod = 'newPaymentMethod'

type NewAddress = 'newAddress'
const NewAddress: NewAddress = 'newAddress'

type NoAddress = 'noAddress'
const NoAddress: NoAddress = 'noAddress'

type SameAddress = 'sameAddress'
const SameAddress: SameAddress = 'sameAddress'

const isRequired = (message?: string) => !!message ? {required: true, message} : {required: true}

enum AddressType {
  BILLING,
  SHIPPING
}

const keyValueColumns = [
  {key: 'key', dataIndex: 'key', render: (value) => <Text strong>{value}</Text>},
  {key: 'value', dataIndex: 'value'}
]

const ExpandableCard = (props: CardProps & { enabled: boolean }) => {
  const { enabled, children, styles, ...otherProps } = props

  const styleProps = useMemo(() => enabled ? {styles} : ({
    styles: {
      ...(!!styles ? styles : {}),
      body: {
        padding: 0
      }
    }
  }), [enabled, styles])

  return <Card
    {...otherProps}
    {...styleProps}
  >
    {enabled ? children : <></>}
  </Card>
}

const ToggleCard = (props: CardProps) => {
  const [ enabled, setEnabled ] = useState<boolean>(false)

  const { children, ...otherProps } = props

  const styleProps = useMemo(() => enabled ? {} : ({
    styles: {
      ...(props.styles),
      body: {
        padding: 0
      }
    }
  }), [enabled])
  
  return <Card
    {...otherProps}
    {...styleProps}
    extra={<>
      <Switch
        onChange={v => setEnabled(v)}
      />
    </>}
  >
    {enabled ? children : <></>}
  </Card>
}

const MerchantSelector = ({ onSelect }: { onSelect: (mid: string) => void }) => {
  const { merchants, merchantsLoaded } = usePortalResources()

  const options = useMemo(() => {
    if (!merchants) return []
    return merchants.map(m => ({label: getMerchantDisplayName(m), key: m.id, value: m.id}))
  }, [merchants])

  return <>
    <Form.Item layout='vertical' label='Merchant'>
      <Select disabled={!merchantsLoaded} loading={!merchantsLoaded} options={options} onChange={mid => onSelect(mid)} />
    </Form.Item>
  </>
}

const GatewaySelector = ({ merchantId }: { merchantId: string | undefined }) => {
  const { getGateways } = usePaylinkAPI()

  const [ gateways, setGateways ] = useState<Gateway[] | undefined>(undefined)
  const [ loading, setLoading ] = useState<boolean>(false)

  useEffect(() => {
    if (!merchantId) {
      setLoading(false)
      setGateways([])
      return
    }
    (async () => {
      setLoading(true)
    })()
      .then(_ => getGateways(merchantId))
      .then(v => {
        setGateways(v.gateways)
        setLoading(false)
      })
  }, [merchantId])

  const options = useMemo(() => {
    if (!gateways) return []
    return gateways.map(g => ({label: g.name, key: g.id, value: g.id}))
  }, [gateways])

  return <>
    <Form.Item layout='vertical' name='gatewayId' label='Gateway' rules={[isRequired('Please select a gateway')]}>
      <Select disabled={!merchantId || loading} loading={loading} options={options} />
    </Form.Item>
  </>
}

type RegionCascaderOptions = RegionCascaderJurisdiction[]

type RegionCascaderJurisdiction = {
  value: Jurisdiction,
  label: ReactNode,
  children: RegionCascaderRegion[]
}

type RegionCascaderRegion = {
  value: string,
  label: ReactNode
}

export const regionCascaderOptions: RegionCascaderOptions = Object.entries(regionsByJurisdiction).map(([jurisdiction, regions]) => {
  const children = regions.map(r => ({value: r.key, label: r.name}))
  return {value: jurisdiction as Jurisdiction, label: jurisdiction, children}
})

export const RegionSelector = () => {
  console.log(regionCascaderOptions)
  return <Cascader onChange={v => console.log(v)} placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
}

const SurchargeCard = () => {
  return <ToggleCard
    title='Surcharge'
  >
  </ToggleCard>
}

const PaymentCard = ({ merchantId, gatewayId, iFrameKey, submit, onTokenReceived }: {merchantId: string | undefined, gatewayId: string | undefined, iFrameKey: string, submit: boolean, onTokenReceived: (data: any) => void}) => {
  return <Card title='Payment'>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        {!!merchantId && !!gatewayId
          ? <PaylinkIFrame key={iFrameKey} merchantId={merchantId} gatewayId={gatewayId} submit={submit} onTokenReceived={onTokenReceived} />
          : <Empty description='Please select a merchant and/or gateway' />
        }
        <Form.Item layout='vertical' label='Name on Card' name={['card', 'nameOnCard']} rules={[isRequired('Please enter the name on this card')]}>
          <Input placeholder='John Doe' />
        </Form.Item>
        <Form.Item layout='vertical' label='Persist Card' name={['card', 'persist']} valuePropName="checked" initialValue={true}>
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  </Card>
}

const AddressForm = ({ path }: {path: string[]}) => {
  return <Row gutter={[16, 16]}>
    <Col span={12}>
      <Form.Item layout='vertical' label='First Name' name={[...path, 'firstName']} rules={[isRequired('Please enter a first name')]}>
        <Input placeholder='John' />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Last Name' name={[...path, 'lastName']} rules={[isRequired('Please enter a last name')]}>
        <Input placeholder='Doe' />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item layout='vertical' label='Address' required>
        <Form.Item noStyle name={[...path, 'address', 'line1']} rules={[isRequired('Please enter a street address')]}>
          <Input placeholder='1 Sample Drive' />
        </Form.Item>
        <Form.Item noStyle name={[...path, 'address', 'line2']}>
          <Input placeholder='Unit 1' style={{marginBlockStart: '0.25em'}} />
        </Form.Item>
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='City' name={[...path, 'city']} rules={[isRequired('Please enter a city')]}>
        <Input placeholder='Sample City' />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='Region' name={[...path, 'region']} rules={[{ type: 'array', required: true }]}>
        <Cascader onChange={v => console.log(v)} placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='Postal Code' name={[...path, 'postalCode']} rules={[isRequired('Please enter a postal code')]}>
        <Input placeholder='11111' />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Phone Number' name={[...path, 'phoneNumber']} rules={[isRequired('Please enter a phone number')]}>
        <PhoneInput />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Email' name={[...path, 'email']} rules={[isRequired('Please enter an email address'), {type: 'email', message: 'Please enter a valid email address'}]}>
        <Input placeholder='john@doe.com' />
      </Form.Item>
    </Col>
  </Row>
}

type BillingAddressCard_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
  paymentMethodId: string | undefined
}
const BillingAddressCard = ({ merchantId, customerId, paymentMethodId }: BillingAddressCard_Props) => {
  const form = useFormInstance()

  const [ address, setAddress ] = useState<string>(NewAddress)

  useEffect(() => {
    if (paymentMethodId !== NewPaymentMethod) {

    }
  }, [paymentMethodId])

  return <ExpandableCard
    enabled={address === NewAddress}
    title='Billing Address'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['billingAddress', 'type']} rules={[isRequired('Please select an option')]} initialValue={address}>
      <AddressSelector defaultValue={NewAddress} onChange={v => setAddress(v)} merchantId={merchantId} customerId={customerId} type={AddressType.BILLING} />
    </Form.Item>}
  >
    <AddressForm path={['billingAddress']} />
  </ExpandableCard>
}

type ShippingAddressCard_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
}
const ShippingAddressCard = ({ merchantId, customerId }: ShippingAddressCard_Props) => {
  const [ address, setAddress ] = useState<string>(NoAddress)

  return <ExpandableCard
    enabled={address === NewAddress}
    title='Shipping Address'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['shippingAddress', 'type']} rules={[isRequired('Please select an option')]} initialValue={address}>
      <AddressSelector defaultValue={NoAddress} onChange={v => setAddress(v)} merchantId={merchantId} customerId={customerId} type={AddressType.SHIPPING} />
    </Form.Item>}
  >
    <AddressForm path={['shippingAddress']} />
  </ExpandableCard>
}

const PaymentDetailsDrawer = () => {
  return <>
  
  </>
}

type CustomerSelector_Props = {
  merchantId: string | undefined,
  gatewayId: string | undefined,
}
const CustomerSelector = ({ merchantId, gatewayId }: CustomerSelector_Props) => {
  const { getCustomers } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ customers, setCustomers ] = useState<Customer[]>([])

  const options = useMemo(() => {
    return [
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Customer</Text>, key: NewCustomer, value: NewCustomer},
      ...(customers.map((c, i) => {
        return {label: <Text><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />{c.firstName} {c.lastName}{!!c.companyName ? <Text type='secondary'> ({c.companyName})</Text> : <></>} </Text>, key: c.id, value: c.id, customer: c}
      }))
    ]
  }, [customers])

  useEffect(() => {
    if (!merchantId || !gatewayId) {
      setCustomers([])
      setLoading(false)
      return
    }
    setLoading(true)
    getCustomers(merchantId)
      .then(c => {
        setCustomers(c.customers)
        setLoading(false)
      })
  }, [merchantId, gatewayId])

  return <Form.Item layout='vertical' label='Customer' name='customerId' rules={[isRequired('Please select a customer')]}>
    <Select
      filterOption={(value, option) => {
        if (!option) return false
        if (option.key === NewCustomer) return true
        const searchValue = value.toLowerCase()
        const optionCustomer = (option as {customer: Customer}).customer
        const optionName = `${optionCustomer.firstName} ${optionCustomer.lastName}`.toLowerCase()
        const optionCompany = (optionCustomer.companyName || '').toLowerCase()
        return [optionName, optionCompany].some(v => v.includes(searchValue))
      }}
      disabled={!merchantId || !gatewayId || loading}
      loading={loading}
      showSearch
      options={options}
    />
  </Form.Item>
}

type CardLabel_Props = {
  card: CardMethod,
}
const CardLabel = ({ card }: CardLabel_Props) => {
  return <Text>
    <CreditCardTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />
    {card.cardNumber}
    <Text type='secondary'> ({card.nameOnCard})</Text>
  </Text>
}

type PaymentMethodSelector_Props = {
  merchantId: string | undefined,
  gatewayId: string | undefined,
  customerId: string | undefined,
}
type PaymentCard = any
type BankAccount = any
const PaymentMethodSelector = ({ merchantId, gatewayId, customerId }: PaymentMethodSelector_Props) => {
  const { getCards, getBankAccounts } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ cards, setCards ] = useState<PaymentCard[]>([])
  const [ bankAccounts, setBankAccounts ] = useState<BankAccount[]>([])

  const options = useMemo(() => {
    console.log(cards, bankAccounts)
    return [
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Payment Method</Text>, key: NewPaymentMethod, value: NewPaymentMethod},
      ...(cards.map((c, i) => {
        return {label: <CardLabel card={c} />, key: c.id, value: c.id}
      })),
      ...(bankAccounts.map((b, i) => {
        console.log(b)
        return {label: <Text><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Bank Account <Text type='secondary'>(Bank Account)</Text></Text>, key: b.id, value: b.id}
      }))
    ]
  }, [cards, bankAccounts])

  useEffect(() => {
    if (!merchantId || !customerId || customerId === NewCustomer) {
      setCards([])
      setBankAccounts([])
      setLoading(false)
      return
    }
    setLoading(true)
    Promise.all([getCards(merchantId, customerId), getBankAccounts(merchantId, customerId)])
      .then(([c, b]) => {
        setCards(c.cards)
        setBankAccounts(b.bankAccounts)
        setLoading(false)
      })
  }, [merchantId, customerId])

  return <Form.Item layout='vertical' label='Payment Method' name='paymentMethodId' rules={[isRequired('Please select a payment method')]}>
    <Select
      disabled={!merchantId || !customerId || loading}
      loading={loading}
      options={options}
    />
  </Form.Item>
}

type AddressLabel_Props = {
  address: Address,
}
const AddressLabel = ({ address }: AddressLabel_Props) => {
  return <Text>
    <ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />
    {address.firstName} {address.lastName}
    <Text type='secondary'> ({address.line1}, {address.locality}, {address.region} {address.postalCode})</Text>
  </Text>
}

type AddressSelector_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
  type: AddressType,
}
const AddressSelector = (props: AddressSelector_Props & SelectProps) => {
  const { merchantId, customerId, type, ...selectProps } = props
  
  const { getAddresses } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ addresses, setAddresses ] = useState<Address[]>([])

  const options = useMemo(() => {
    return [
      ...(type === AddressType.SHIPPING ? [
        {label: <Text type='secondary'>No Shipping Address</Text>, key: NoAddress, value: NoAddress},
        {label: <Text strong style={{color: blue.primary}}><HomeTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Same As Billing Address</Text>, key: SameAddress, value: SameAddress}
      ] : []),
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Address</Text>, key: NewAddress, value: NewAddress},
      ...(addresses.map((a, i) => {
        console.log(a)
        return {label: <AddressLabel address={a} />, key: a.id, value: a.id}
      })),
    ]
  }, [addresses])

  useEffect(() => {
    if (!merchantId || !customerId || customerId === NewCustomer) {
      setAddresses([])
      setLoading(false)
      return
    }
    setLoading(true)
    getAddresses(merchantId, customerId)
      .then(a => {
        setAddresses(a.addresses)
        setLoading(false)
      })
  }, [merchantId, customerId])

  return <Select
    style={{width: '100%'}}
    disabled={!merchantId || !customerId || loading}
    loading={loading}
    options={options}
    {...selectProps}
  />
}

const TransactionFeeConfirmationModal = ({ paymentData, transactionFeeData, onConfirm, onCancel }: {paymentData: DoIt_Shape, transactionFeeData: TransactionFeeResponse | undefined, onConfirm: () => void, onCancel: () => void}) => {
  const subtotal = paymentData.amount

  if (!transactionFeeData) {
    return <Modal open onOk={onConfirm} onCancel={onCancel} title='Surcharge Disclosure'>
      <Paragraph>No additional fee will be applied to this payment</Paragraph>
      <Table
        columns={keyValueColumns}
        dataSource={[
          {key: 'Total', value: (subtotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
        ]}
        pagination={false}
        showHeader={false}
      />
    </Modal>
  }

  const transactionFee = transactionFeeData.transactionFee.amount
  const total = subtotal + transactionFee

  return <Modal open onOk={onConfirm} title='Surcharge Disclosure'>
    <Paragraph>We may apply a fee to cover all or part of our costs of accepting credit cards:</Paragraph>
    <Table
      columns={keyValueColumns}
      dataSource={[
        {key: 'Subtotal', value: (subtotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'Transaction Fee', value: (transactionFee / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'Total', value: (total / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
      ]}
      pagination={false}
      showHeader={false}
    />
  </Modal>
}

type AddressForm_Shape = {
  firstName: string,
  lastName: string,
  address: {
    line1: string,
    line2?: string
  },
  city: string,
  region: [string, string],
  postalCode: string,
  phoneNumber: string,
  email: string
}

type VTForm_ExistingCustomer = {
  customerId: string,
}

type VTForm_NewCustomer = {
  customerId: NewCustomer,
  customer: {
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    phoneNumber: string,
    merchantAccountNumber: string,
  }
}

type VTForm_Customer = VTForm_ExistingCustomer | VTForm_NewCustomer

type VTForm_NoAddress = undefined

type VTForm_ExistingAddress = {
  type: string,
}

type VTForm_NewAddress = {
  type: NewAddress,
} & AddressForm_Shape

type VTForm_Address = VTForm_ExistingAddress | VTForm_NewAddress | VTForm_NoAddress

type VTForm_Shape = {
  gatewayId: string,
  paymentMethodId: string,
  amount: number,
  surcharge: boolean,
  paymentType: PaymentType,
  card: {
    nameOnCard: string,
    persist: boolean,
  },
  billingAddress: VTForm_Address,
  shippingAddress: VTForm_Address,
  invoiceNumbers: string[],
  purchaseOrderNumbers: string[],
} & VTForm_Customer

type NewPaymentMethodToken = {
  nonce: string,
}
type ExistingPaymentMethodToken = {
  paymentMethodToken: string,
}

type PaymentMethodToken = NewPaymentMethodToken | ExistingPaymentMethodToken

type DoIt_Shape = VTForm_Shape & PaymentMethodToken

export const PaylinkVirtualTerminalView = ({merchant}: {merchant: Merchant}) => {
  const history = useHistory()

  const [ message, contextHolder ] = useMessage()

  const [ form ] = useForm()

  const mid = merchant.id!

  const { createPayment, createTransactionFee, getCard } = usePaylinkAPI()

  const { isAuthorized, actionsLoaded } = useAuthorization(`merchant:${mid}`, [UserRole.ADMIN, UserRole.PAYLINK_ADMIN])
  const notAuthorized = useMemo(() => actionsLoaded && !isAuthorized('admin'), [actionsLoaded, isAuthorized])

  const [ loading, setLoading ] = useState<boolean>(false)

  const [ iFrameKey, setIFrameKey ] = useState<string>(v4())
  const [ submitIFrame, setSubmitIFrame ] = useState<boolean>(false)

  const [ storedPaymentData, setStoredPaymentData ] = useState<DoIt_Shape | undefined>(undefined)
  const [ storedTransactionFeeData, setStoredTransactionFeeData ] = useState<TransactionFeeResponse | undefined>(undefined)
  const [ showTransactionFeeConfirmation, setShowTransactionFeeConfirmation ] = useState<boolean>(false)

  const reset = useCallback(() => {
    setLoading(false)
    setIFrameKey(v4())
    setSubmitIFrame(false)
    setStoredPaymentData(undefined)
    setStoredTransactionFeeData(undefined)
    setShowTransactionFeeConfirmation(false)
  }, [mid, setLoading, setIFrameKey, setSubmitIFrame, setStoredPaymentData, setStoredTransactionFeeData, setShowTransactionFeeConfirmation])

  const adjustValues = useCallback((naiveValues: VTForm_Shape) => {
    const amount = Math.floor(naiveValues.amount * 100)
    return {...naiveValues, amount}
  }, [])

  const onTokenReceived = useCallback(async (data: any) => {
    // Second step for iFrame-included flows
    message.info({content: 'Card tokenized successfully!', key: 'tokenizeCard'})
    const values: VTForm_Shape = adjustValues(form.getFieldsValue())
    onPaymentSubmission({...values, nonce: data.nonce})
  }, [form])

  const submitPaymentForm = useCallback(async (naiveValues: VTForm_Shape) => {
    if (!mid) return

    const values = adjustValues(naiveValues)

    if (values.paymentMethodId === NewPaymentMethod) {
      // If we are submitting a new payment method, use IFrame's onMessageReceived to call second-stage logic
      message.info({content: 'Tokenizing card...', key: 'tokenizeCard'})
      setLoading(true)
      setSubmitIFrame(true)
      return
    }

    // Else call it directly
    setLoading(true)
    message.info({content: 'Loading stored card...', key: 'storedCard'})
    const card = await getCard(mid, values.paymentMethodId).then(r => r.card)
    message.success({content: 'Stored card loaded successfully!', key: 'storedCard'})
    return onPaymentSubmission({...values, paymentMethodToken: card.token})
  }, [mid])

  const calculateSurcharge = useCallback(async (values: DoIt_Shape) => {
    if (!mid) return Promise.reject()

    if ('nonce' in values) {
      return createTransactionFee(mid, {idempotencyKey: v4(), transactionFee: {gatewayId: values.gatewayId, paymentMethodNonce: values.nonce, transaction: {amount: values.amount}}})
    } else if ('paymentMethodToken' in values) {
      return createTransactionFee(mid, {idempotencyKey: v4(), transactionFee: {gatewayId: values.gatewayId, paymentMethodToken: values.paymentMethodToken, transaction: {amount: values.amount}}})
    }

    return Promise.reject()
  }, [mid])

  const onPaymentSubmission = useCallback(async (values: DoIt_Shape) => {
    if (!mid) return

    const { surcharge } = values
    
    if (surcharge) {
      message.info({content: 'Calculating surcharge...', key: 'surcharge'})
      const surchargeResponse = await calculateSurcharge(values)
      message.success({content: 'Surcharge calculated successfully!', key: 'surcharge'})
      setStoredPaymentData(values)
      if (!surchargeResponse) {
        console.warn('No surcharge could be calculated')
        setStoredTransactionFeeData(undefined)
      } else {
        setStoredTransactionFeeData(surchargeResponse)
      }
    } else {
      setStoredPaymentData(values)
      setStoredTransactionFeeData(undefined)
    }

    setShowTransactionFeeConfirmation(true)
  }, [mid])

  const onTransactionFeeConfirmation = useCallback(async () => {
    if (!mid) return

    setShowTransactionFeeConfirmation(false)
    message.info({content: 'Submitting payment...', key: 'payment'})

    const values = storedPaymentData as DoIt_Shape
    const transactionFeeId = !!storedTransactionFeeData ? storedTransactionFeeData.transactionFee.id : undefined

    const { gatewayId } = values

    const customerValues = getCustomerValues(values)
    const shippingAddressValues = (!!values.shippingAddress && values.shippingAddress.type === SameAddress) ? getAddressValues(values.billingAddress) : getAddressValues(values.shippingAddress)
    const paymentValues = getPaymentValues(values)

    const paymentRequest = {
      idempotencyKey: v4(),
      payment: {
        gatewayId,
        capture: values.paymentType === PaymentType.SALE,
        ...shippingAddressValues,
        ...customerValues,
        ...paymentValues,
        merchantReferenceCode: v4(),
        amount: values.amount,
        ...(!!transactionFeeId ? {transactionFeeId} : {}),
        invoiceNumbers: values.invoiceNumbers,
        purchaseOrderNumbers: values.purchaseOrderNumbers,
      }
    }

    return createPayment(mid, paymentRequest as any)
      .then(payment => {
        message.success({ content: 'Payment submitted successfully!', key: 'payment' })
        notification.success({ message: <>
          <Paragraph>Payment created!</Paragraph>
          <Button type='primary' icon={<BookOutlined />} onClick={() => history.push(`/pl/${mid}/payments/${payment.payment.id}`)}>Go to Payment</Button>
        </>, key: payment.payment.id })
      })
      .catch(error => {
        console.error('[PaylinkVirtualTerminalView] Error during payment creation', error)
        message.error({ content: 'Payment could not be created...', key: 'payment' })
      })
      .then(_ => {
        reset()
      })

  }, [mid, storedPaymentData, storedTransactionFeeData])

  const getCustomerValues = useCallback((values: VTForm_Shape) => {
    const { customerId } = values
    if (customerId === NewCustomer) {
      const scopedValues = values as (VTForm_Shape & VTForm_NewCustomer)
      const { customer } = scopedValues
      return {
        customer: {
          firstName: customer.firstName,
          lastName: customer.lastName,
          companyName: customer.companyName,
          email: customer.email,
          phoneNumber: customer.phoneNumber.replace(/\D/g, ""),
          merchantAccountNumber: customer.merchantAccountNumber,
        }
      }
    } else {
      return { customerId }
    }
  }, [mid])

  const getPaymentValues = useCallback((values: DoIt_Shape) => {
    const anyValues = values as any
    if (anyValues.paymentMethodToken !== undefined) return { paymentMethodToken: anyValues.paymentMethodToken }
    return {
      card: {
        nonce: anyValues.nonce,
        nameOnCard: values.card.nameOnCard,
        persist: values.card.persist,
        ...getAddressValues(values.billingAddress)
      }
    }
  }, [mid])

  const getAddressValues = useCallback((address: VTForm_Address) => {
    if (address === undefined || address.type === NoAddress) return {}
    if (address.type === NewAddress) {
      const newAddress = address as VTForm_NewAddress
      const { firstName, lastName, city, region, postalCode, phoneNumber, email } = newAddress
      return {address: {
        firstName,
        lastName,
        line1: newAddress.address.line1,
        ...(!!newAddress.address.line2 ? {line2: newAddress.address.line2} : {}),
        locality: city,
        region: region[1],
        country: region[0],
        postalCode: postalCode,
        phoneNumber: phoneNumber.replace(/\D/g, ""),
        email: email,
        persist: false,
      }}
    }
    return {addressId: address.type}
  }, [mid])

  if (notAuthorized) return <></>
  return <>
    {contextHolder}
    {showTransactionFeeConfirmation ? <TransactionFeeConfirmationModal paymentData={storedPaymentData as DoIt_Shape} transactionFeeData={storedTransactionFeeData} onConfirm={() => onTransactionFeeConfirmation()} onCancel={() => reset()} /> : <></>}
    <Spin spinning={loading}>
      <Form form={form} component={false} onFinish={(values) => submitPaymentForm(values)}>
        <Row gutter={[32, 32]}>
            {/* Main page */}
            <Col xs={24}>
              <Row gutter={[32, 32]}>
                <Col span={24}>
                  <Result
                    icon={<DollarOutlined />}
                    extra={<>
                      <Typography.Title level={2}>New Payment</Typography.Title>
                      <div style={{maxWidth: '80ch', margin: '0 auto', fontSize: 'clamp(0.75rem, calc(0.75rem + 0.25vw), 1.125rem)'}}>
                        {/* Idk put some copy here */}
                      </div>
                    </>}
                  />
                </Col>
                
                <Col span={24}>
                  <Card title="Scope">
                    <Row gutter={[32, 32]}>
                      <Col span={12}>
                        <GatewaySelector merchantId={mid} />
                      </Col>

                      <Col span={12}>
                        <Form.Item noStyle shouldUpdate={(last, current) => last.gatewayId !== current.gatewayId}>
                          {({getFieldValue}) => {
                            const gatewayId = getFieldValue('gatewayId')
                            return <CustomerSelector merchantId={mid} gatewayId={gatewayId} />
                          }}
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item noStyle shouldUpdate={(last, current) => (last.gatewayId !== current.gatewayId) || (last.customerId !== current.customerId)}>
                          {({getFieldValue}) => {
                            const gatewayId = getFieldValue('gatewayId')
                            const customerId = getFieldValue('customerId')
                            return <PaymentMethodSelector merchantId={mid} gatewayId={gatewayId} customerId={customerId} />
                          }}
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item layout='vertical' label='Amount' name='amount' rules={[isRequired('Please enter a transaction amount')]}>
                          <InputNumber
                            addonBefore='$'
                            min={0}
                            step={0.01}
                            precision={2}
                            style={{width: '100%'}}
                            formatter={commaSeparatedFormatter}
                            parser={commaSeparatedParser}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item layout='vertical' label='Payment Type' name='paymentType' rules={[isRequired('Please select a payment type')]}>
                          <Select options={Object.values(PaymentType).map(t => ({label: t, key: t, value: t}))} />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item layout='vertical' label='Apply Surcharge?' name='surcharge' valuePropName="checked" initialValue={true}>
                          <Switch disabled={!isAuthorized(UserRole.PAYLINK_ADMIN)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col span={24}>
                  <Card title='Details'>
                    <Row gutter={[32, 32]}>
                      <Col span={12}>
                        <Form.Item layout='vertical' label='Invoice Number(s)' name='invoiceNumbers' rules={[]}>
                          <Select
                            style={{width: '100%'}}
                            mode='tags'
                            tokenSeparators={[',', '\r\n', '\n', '\t']}
                            tagRender={({label, onClose}: CustomTagProps) => {
                              const handleCopy = (event: React.MouseEvent<HTMLSpanElement>) => {
                                // prevent bubbling through the component and selecting the parent input
                                event.preventDefault()
                                event.stopPropagation()

                                // copy text to the clipboard
                                const labelText = label!.toString()
                                navigator.clipboard.writeText(labelText)
                                  .then(_ => message.success(`Copied invoice number ${labelText} to the clipboard`))
                              }

                              return (
                                <Tooltip title={'Double click to copy'}>
                                  <Tag
                                    style={{cursor: 'pointer'}}
                                    closable={true}
                                    onDoubleClick={handleCopy}
                                    onClose={onClose}
                                  >
                                    {label}
                                  </Tag>
                                </Tooltip>
                              )
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item layout='vertical' label='Purchase Order Number(s)' name='purchaseOrderNumbers' rules={[]}>
                          <Select
                            style={{width: '100%'}}
                            mode='tags'
                            tokenSeparators={[',', '\r\n', '\n', '\t']}
                            tagRender={({label, onClose}: CustomTagProps) => {
                              const handleCopy = (event: React.MouseEvent<HTMLSpanElement>) => {
                                // prevent bubbling through the component and selecting the parent input
                                event.preventDefault()
                                event.stopPropagation()

                                // copy text to the clipboard
                                const labelText = label!.toString()
                                navigator.clipboard.writeText(labelText)
                                  .then(_ => message.success(`Copied purchase order number ${labelText} to the clipboard`))
                              }

                              return (
                                <Tooltip title={'Double click to copy'}>
                                  <Tag
                                    style={{cursor: 'pointer'}}
                                    closable={true}
                                    onDoubleClick={handleCopy}
                                    onClose={onClose}
                                  >
                                    {label}
                                  </Tag>
                                </Tooltip>
                              )
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId}>
                  {({getFieldValue}) => {
                    const customerId = getFieldValue('customerId')
                    if (customerId === NewCustomer) return <>
                      <Col span={24}>
                        <Card title='Customer'>
                          <Row gutter={[32, 32]}>
                            <Col span={12}>
                              <Form.Item label='First Name' name={['customer', 'firstName']} layout='vertical' rules={[isRequired('Please enter a first name')]}>
                                <Input placeholder='John' />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label='Last Name' name={['customer', 'lastName']} layout='vertical' rules={[isRequired('Please enter a last name')]}>
                                <Input placeholder='Doe' />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label='Phone Number' name={['customer', 'phoneNumber']} layout='vertical' rules={[isRequired('Please enter a phone number')]}>
                                <PhoneInput />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label='Email' name={['customer', 'email']} layout='vertical' rules={[isRequired('Please enter an email address'), {type: 'email', message: 'Please enter a valid email address'}]}>
                                <Input placeholder='john@doe.com' />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label='Company Name' name={['customer', 'companyName']} layout='vertical' rules={[isRequired('Please enter a company name')]}>
                                <Input placeholder='Placeholder Industries' />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label='Merchant Account Number' name={['customer', 'merchantAccountNumber']} layout='vertical' rules={[isRequired('Please enter a merchant account number')]}>
                                <Input placeholder='123456789000000' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </>
                    return <></>
                  }}
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(last, current) => last.paymentMethodId !== current.paymentMethodId}>
                  {({getFieldValue}) => {
                    const gatewayId = getFieldValue('gatewayId')
                    const paymentMethodId = getFieldValue('paymentMethodId')
                    if (paymentMethodId === NewPaymentMethod) return <>
                      <Col span={24}>
                        <PaymentCard iFrameKey={iFrameKey} merchantId={mid} gatewayId={gatewayId} submit={submitIFrame} onTokenReceived={onTokenReceived} />
                      </Col>
                    </>
                    return <></>
                  }}
                </Form.Item>
                
                <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId || last.paymentMethodId !== current.paymentMethodId}>
                  {({getFieldValue}) => {
                    const customerId = getFieldValue('customerId')
                    const paymentMethodId = getFieldValue('paymentMethodId')
                    if (paymentMethodId !== NewPaymentMethod) return <></>
                    return <Col span={24}>
                      <BillingAddressCard merchantId={mid} customerId={customerId} paymentMethodId={paymentMethodId} />
                    </Col>
                  }}
                </Form.Item>

                <Col span={24}>
                  <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId}>
                    {({getFieldValue}) => {
                      const customerId = getFieldValue('customerId')
                      return <ShippingAddressCard merchantId={mid} customerId={customerId} />
                    }}
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col span={24}>
              <Button type='primary' block icon={<DollarOutlined />} onClick={_ => form.submit()}>Pay</Button>
            </Col>

        </Row>

        <br />
      </Form>
    </Spin>
  </>
}