import { blue, red } from "@ant-design/colors"
import { ApiOutlined, BankOutlined, BookOutlined, ContactsOutlined, CreditCardOutlined, DeleteOutlined, DollarCircleOutlined, DollarOutlined, EditOutlined, ExclamationCircleOutlined, ExportOutlined, FieldTimeOutlined, HomeOutlined, MailOutlined, PhoneOutlined, PlusOutlined, StarOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Card, Col, Divider, Form, Input, Layout, Menu, message, Popconfirm, Result, Row, Select, Skeleton, Space, Spin, Statistic, Table, Tag, Tooltip, Typography } from "antd"
import { Content } from "antd/es/layout/layout"
import Sider from "antd/es/layout/Sider"
import { ItemType } from "antd/es/menu/interface"
import { ColumnsType } from "antd/es/table"
import { FancyCard } from "components/Card"
import { CustomerForm } from "components/paylink/CustomerForm"
import { GatewayForm } from "components/paylink/GatewayForm"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { useTableSearch } from "hooks/useTableSearch"
import { DefaultEmailMaxLength, formLayout, getMerchantDisplayName, humanize, Invite, maxLengthRule, Merchant, tailLayout, User, UserRole } from "models/models"
import { Address, CardMethod, Customer, CustomerRequest, Gateway, GatewayRequest, getCustomerName, InviteRequest, Payment, PaymentStatus, PaymentType } from "models/paylink"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { PaylinkVirtualTerminalView } from "./PaylinkVirtualTerminalView"
import { AddressForm, AddressRequest } from "components/paylink/AddressForm"
import { useMerchantAPI } from "hooks/useMerchantAPI"
import dayjs from "dayjs"
import Loading from "components/Loading/Loading"
import { RoleTag } from "components/RoleTag"
import { useAuthorization } from "hooks/useAuthorization"
import FileSaver from "file-saver"

const { Title } = Typography

const paymentCsvColumns: {title: string, getValue: (record: Payment) => string}[] = [
  {title: 'ID', getValue: record => record.id},
  {title: 'Invoice Numbers', getValue: record => (record.invoiceNumbers || []).join(';')},
  {title: 'Purchase Order Numbers', getValue: record => (record.purchaseOrderNumbers || []).join(';')}
]

const PaymentTable = ({mid, payments, loading}: {mid: string, payments: Payment[], loading: boolean}) => {
  const history = useHistory()
  const { isAuthorized } = useAuthorization('*', ['admin'])

  const { getSearchProps } = useTableSearch<Payment>()

  const [ filteredData, setFilteredData ] = useState<Payment[]>(payments)

  const columns: ColumnsType<Payment> = useMemo(() => [
    {
      title: 'ID',
      key: 'id',
      render: (_, payment) => {
        return <>
          {(!!payment.invoiceNumbers && !!payment.invoiceNumbers.length) ? <>
            <Text strong>Invoice: </Text><Text>{(payment.invoiceNumbers || []).map((n, i) => <Tag key={i}>{n}</Tag>)}</Text>
            <br />
          </> : <></>}
          {(!!payment.purchaseOrderNumbers && !!payment.purchaseOrderNumbers.length) ? <>
            <Text strong>PO: </Text><Text>{(payment.purchaseOrderNumbers || []).map((n, i) => <Tag key={i}>{n}</Tag>)}</Text>
            <br />
          </> : <></>}
          {isAuthorized('admin') ? <><Text strong>ID: </Text><Tag>{payment.id}</Tag></> : <></>}
        </>
      },
      ...getSearchProps((value, record) => {
        const id = record.id.toLowerCase()
        const invoiceNumbers = (record.invoiceNumbers || []).map(n => n.toLowerCase())
        const purchaseOrderNumbers = (record.purchaseOrderNumbers || []).map(n => n.toLowerCase())
        const searchValue = value.toString().toLowerCase()
        return !![id, ...invoiceNumbers, ...purchaseOrderNumbers].find(v => v.includes(searchValue))
      })
    },
    {
      title: 'Created At',
      key: 'createdAt', 
      render: (_, payment) => <Text>{humanize(payment.creationMoment)}</Text>,
      sorter: (a, b) => dayjs(a.creationMoment).diff(dayjs(b.creationMoment)),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_, payment) => <>
        <Text strong>Subtotal: </Text><Text>{(payment.captureAmount / 100).toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
        <br />
        {!!payment.surchargeAmount ? <>
          <Text strong>Surcharge: </Text><Text>{(payment.surchargeAmount / 100).toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
          <br />
        </> : <></>}
        <Text strong>Total: </Text><Text>{((payment.captureAmount + (payment.surchargeAmount || 0)) / 100).toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
      </>
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, payment) => <Text>{payment.paymentStatus}</Text>,
      filters: [
        {
          value: PaymentStatus.CAPTURED,
          text: 'Captured'
        },
        {
          value: PaymentStatus.AUTHORIZED,
          text: 'Authorized'
        },
        {
          value: PaymentStatus.VOIDED,
          text: 'Voided'
        }
      ],
      onFilter: (value, record) => record.paymentStatus === value
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, payment) => <Text>{payment.paymentType}</Text>,
      filters: [
        {
          value: PaymentType.SALE,
          text: 'Sale'
        },
        {
          value: PaymentType.AUTHORIZATION,
          text: 'Authorization'
        }
      ],
      onFilter: (value, record) => record.paymentType === value
    },
    {
      key: 'actions',
      render: (_, payment) => <div style={{textAlign: 'right'}}>
        <Button type='primary' ghost icon={<BookOutlined />} onClick={_ => history.push(`/pl/${mid}/payments/${payment.id}`)} />
      </div>
    }
  ], [isAuthorized])

  const exportToCSV = useCallback(() => {
    console.log(filteredData)
    const titleColumn = paymentCsvColumns.map(c => c.title).join(',')
    const dataColumns = filteredData.map(d => paymentCsvColumns.map(c => c.getValue(d)).join(','))
    const output = [titleColumn, ...dataColumns].join('\r\n')
    const timestamp = dayjs().format('YYMMDDHHmmss')
    FileSaver.saveAs(new Blob([output], {type: "text/plain;charset=utf-8"}), `payments_${timestamp}.csv`)
  }, [filteredData])

  useEffect(() => {
    setFilteredData(payments)
  }, [payments])

  return <Spin spinning={loading}>
    <Table
      onChange={(p, f, s, extra) => setFilteredData(extra.currentDataSource)}
      title={(data) => <div style={{display: 'grid', gridTemplateRows: 'auto', gridTemplateColumns: '1fr auto 1fr', alignItems: 'center'}}>
        <div style={{gridColumn: 2, gridRow: 1, textAlign: 'center'}}>
          <Text strong style={{fontSize: '1rem'}}>Payments</Text>
          <br />
          <Text type='secondary'>Showing {data.length} of {filteredData.length.toString()} {filteredData.length === 1 ? 'payment' : 'payments'}</Text>
        </div>
        <div style={{gridColumn: 3, gridRow: 1, textAlign: 'right'}}>
          <Space size='middle'>
            <Button type={'primary'} icon={<ExportOutlined />} onClick={() => exportToCSV()}>Export</Button>
          </Space>
        </div>
      </div>}
      dataSource={payments}
      columns={columns}
    />
  </Spin>
}

const NewPaymentCard = ({mid}: {mid: string}) => {
  const history = useHistory()

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => history.push(`/pl/${mid}/${MenuKey.PAYMENTS}/new`)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Payment</Title>
  </FancyCard>
}

const PaymentsInner = ({merchant}: {merchant: Merchant}) => {
  const { getPayments } = usePaylinkAPI()

  const mid = merchant.id!

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ payments, setPayments ] = useState<Payment[]>([])

  const refresh = useCallback(() => {
    setLoading(true)
    getPayments(mid)
      .then(p => {
        setPayments(p.payments)
        setLoading(false)
      })
      .catch(e => {
        setPayments([])
        setLoading(false)
      })
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Payments</Title>}
          icon={<DollarCircleOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewPaymentCard mid={mid} />
      </Col>

      <Col span={24}>
        <PaymentTable payments={payments} loading={loading} mid={mid} />
      </Col>
    </Row>
  </>
}

const NewGatewayCard = ({merchant, onRefresh}: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createGateway } = usePaylinkAPI()

  const handleSubmit = useCallback((request: GatewayRequest) => {
    setLoading(true)
    message.info({content: <Text>Creating a new <Text strong>{request.gateway.gatewayType}</Text> gateway <Text type='secondary'>({request.gateway.name})</Text></Text>, key: 'createGateway'})
    createGateway(merchant.id!, request)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Gateway <Text strong>{request.gateway.name}</Text> was created successfully!</Text>, key: 'createGateway'})
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        message.error({content: <Text>Gateway <Text strong>{request.gateway.name}</Text> could not be created</Text>, key: 'createGateway'})
        setLoading(false)
      })
  }, [merchant])

  if (showForm) return <Spin spinning={loading}>
    <Card>
      <GatewayForm merchant={merchant} onSubmit={handleSubmit} onCancel={() => setShowForm(false)} editMode={false} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Gateway</Title>
  </FancyCard>
}

const GatewayLoadingCard = () => {
  return <Card loading={true}>
    <div style={{marginBlockEnd: '1em'}}>
      <Skeleton><Title level={4} style={{marginBlockEnd: '-0.25em'}}>Test Gateway</Title></Skeleton>
      <Skeleton><Text type='secondary' style={{fontSize: '0.75em'}}>abcdefgh-abcd-abcd-abcd-abcdefghijkl</Text></Skeleton>
    </div>
  </Card>
}

const GatewayCard = ({gateway, merchant, onRefresh}: {gateway: Gateway, merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ showForm, setShowForm ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const { updateGateway } = usePaylinkAPI()

  const handleUpdate = useCallback((request: GatewayRequest) => {
    if (!merchant || !gateway) return
    setLoading(true)
    message.info({content: <Text>Updating gateway <Text strong>{gateway.name}</Text></Text>, key: 'updateGateway'})
    updateGateway(merchant.id!, gateway.id, {...request, gateway: {...request.gateway, disabled: false}} as any)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Gateway <Text strong>{gateway.name}</Text> updated successfully!</Text>, key: 'updateGateway'})
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        message.error({content: <Text>Gateway <Text strong>{gateway.name}</Text> could not be updated</Text>, key: 'updateGateway'})
        console.error(`[GatewayCard] handleUpdate was unsuccessful`)
        setLoading(false)
      })
  }, [merchant, gateway])

  const handleDelete = useCallback(() => {
    if (!merchant || !gateway) return
    setLoading(true)
    message.info({content: <Text>Deleting gateway <Text strong>{gateway.name}</Text></Text>, key: 'deleteGateway'})
    updateGateway(merchant.id!, gateway.id!, {gateway: {...gateway, disabled: true}} as any)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Gateway <Text strong>{gateway.name}</Text> deleted successfully!</Text>, key: 'deleteGateway'})
        setLoading(false)
      })
      .catch(e => {
        message.error({content: <Text>Gateway <Text strong>{gateway.name}</Text> could not be deleted</Text>, key: 'deleteGateway'})
        console.error(`[GatewayCard] handleDelete was unsuccessful`)
        setLoading(false)
      })
  }, [merchant, gateway])

  if (showForm) return <Spin spinning={loading}>
    <Card>
      <GatewayForm merchant={merchant} initialValues={gateway} onSubmit={handleUpdate} onCancel={() => setShowForm(false)} editMode={false} />
    </Card>
  </Spin>
  return <Card
    title={<div style={{marginBlock: '1em'}}>
      <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{gateway.name}</Title>
      <Text type='secondary' style={{fontSize: '0.75em'}}>{gateway.id}</Text>
    </div>}
    extra={<Space size='middle'>
      <Button type='primary' icon={<EditOutlined />} onClick={() => setShowForm(true)}>Edit</Button>
      <Popconfirm
        title={<Text>Are you sure you want to delete gateway <Text strong>{gateway.name}</Text>?</Text>}
        onConfirm={() => handleDelete()}
      >
        <Button danger icon={<DeleteOutlined />}>Delete</Button>
      </Popconfirm>
    </Space>}
  >
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1.5em'}}>
      <Text type='secondary'><ApiOutlined style={{marginInlineEnd: '0.5em'}} />Gateway</Text>
      <div>{gateway.gatewayType}</div>

      <Tooltip title='The surcharging processor associated with this gateway'><Text type='secondary'><CreditCardOutlined style={{marginInlineEnd: '0.5em'}} />Processor</Text></Tooltip>
      <div>{gateway.processor}</div>
    </div>
  </Card>
}

const GatewaysInner = ({merchant}: {merchant: Merchant}) => {
  const { getGateways } = usePaylinkAPI()

  const mid = merchant.id!

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ gateways, setGateways ] = useState<Gateway[]>([])

  const refreshGateways = useCallback(() => {
    return getGateways(mid)
      .then(g => {
        setGateways(g.gateways)
      })
      .catch(e => {
        console.error(`[GatewaysInner] refreshGateways: Bad response received from getGateways`)
        setGateways([])
      })
  }, [mid])
  
  const refresh = useCallback(() => {
    setLoading(true)
    return refreshGateways()
      .then(() => {
        setLoading(false)
      })
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Gateways</Title>}
          icon={<ApiOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewGatewayCard merchant={merchant} onRefresh={refresh} />
      </Col>

      {loading && <>
        <Col span={24}>
          <GatewayLoadingCard />
        </Col>
      </>}

      {!loading && gateways.map(g => <Col span={24} key={g.id}>
        <GatewayCard key={g.id} merchant={merchant} gateway={g} onRefresh={refresh} />
      </Col>)}
    </Row>
  </>
}

const CustomersTable = ({mid, customers, loading}: {mid: string, customers: Customer[], loading: boolean}) => {
  const history = useHistory()

  const { getSearchProps } = useTableSearch<Customer>()

  const columns: ColumnsType<Customer> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, customer) => <Text>{customer.firstName} {customer.lastName}</Text>,
      sorter: (a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`),
      ...getSearchProps((value, record) => {
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase()
        const searchValue = value.toString().toLowerCase()
        return fullName.includes(searchValue)
      })
    },
    {
      title: 'Company',
      key: 'company',
      dataIndex: 'companyName',
      sorter: (a, b) => (a.companyName && b.companyName) ? a.companyName.localeCompare(b.companyName) : 0,
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        if (!record.companyName) return !searchValue.length
        const companyValue = record.companyName.toLowerCase()
        return companyValue.includes(searchValue)
      })
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        const recordValue = record.email.toLowerCase()
        return recordValue.includes(searchValue)
      })
    },
    {
      title: 'Merchant Account Number',
      key: 'merchantAccountNumber',
      dataIndex: 'merchantAccountNumber',
      sorter: (a, b) => a.merchantAccountNumber.localeCompare(b.merchantAccountNumber),
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        const recordValue = record.merchantAccountNumber.toLowerCase()
        return recordValue.includes(searchValue)
      })
    },
    {
      key: 'actions',
      render: (_, customer) => <div style={{textAlign: 'right'}}>
        <Button icon={<BookOutlined />} type='primary' ghost onClick={_ => history.push(`/pl/${mid}/customers/${customer.id}`)} />
      </div>
    }
  ]

  return <>
    <Spin spinning={loading}>
      <Table
        dataSource={customers}
        columns={columns}
      />
    </Spin>
  </>
}

const CustomersInner = ({merchant}: {merchant: Merchant}) => {
  const { getCustomers } = usePaylinkAPI()

  const mid = merchant.id!

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ customers, setCustomers ] = useState<Customer[]>([])

  const keyedCustomers = useMemo(() => customers.map(c => ({...c, key: c.id})), [customers])

  const refresh = useCallback(() => {
    setLoading(true)
    return getCustomers(mid)
      .then(c => {
        setCustomers(c.customers)
        setLoading(false)
      })  
      .catch(e => {
        console.error(`[CustomersInner] Bad response received from getCustomers`)
        setCustomers([])
        setLoading(false)
      })
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Customers</Title>}
          icon={<ContactsOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewCustomerCard mid={mid} onRefresh={refresh} />
      </Col>

      <Col span={24}>
        <CustomersTable mid={mid} customers={keyedCustomers} loading={loading} />
      </Col>
    </Row>
  </>
}

const NewAddressCard = ({mid, cid, onRefresh}: {mid: string, cid: string, onRefresh: () => void}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createAddress } = usePaylinkAPI()

  const handleSubmit = useCallback((request: AddressRequest) => {
    setLoading(true)
    return createAddress(mid, cid, {address: request})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Address'>
      <AddressForm onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={_ => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Address</Title>
  </FancyCard>
}

const AddressCard = ({merchant, address, onRefresh}: {merchant: Merchant, address: Address, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { updateAddress } = usePaylinkAPI()

  const handleSetDefault = useCallback(() => {
    if (!merchant || !address) return
    setLoading(true)
    updateAddress(merchant.id!, address.id!, {address: {...address, default: true}})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
      })
  }, [merchant, address])

  const handleUpdate = useCallback((values) => {
    if (!merchant || !address) return
    setLoading(true)
    updateAddress(merchant.id!, address.id!, {address: {...values}})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
  }, [merchant, address])

  const handleDelete = useCallback(() => {
    if (!merchant || !address) return
    setLoading(true)
    updateAddress(merchant.id!, address.id!, {address: {...address, disabled: true}} as any)
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
      })
  }, [merchant, address])

  if (showForm) return <Card>
    <AddressForm onSubmit={values => handleUpdate(values)} onCancel={() => setShowForm(false)} initialValues={address} />
  </Card>

  return <Card
    title={<div style={{marginBlock: '1em'}}>
      <div style={{marginBlockEnd: '0.5em'}}>
        <Title level={4} style={{marginBlockEnd: '-0.25em'}}>
          <span>{address.firstName} {address.lastName}</span>
        </Title>
        <Text type='secondary' style={{fontSize: '0.75em'}}>{address.id}</Text>
      </div>
      <div>
        {!!address.default ? <Space size='small'><Tag color={blue.primary} icon={<StarOutlined />}>Default</Tag></Space> : <></>}
      </div>
    </div>}
    extra={<Space size='small'>
      <Tooltip title='Set Default'><Button icon={<StarOutlined />} onClick={() => handleSetDefault()}></Button></Tooltip>
      <Tooltip title='Edit'><Button icon={<EditOutlined />} type='primary' ghost onClick={() => setShowForm(true)}></Button></Tooltip>
      <Tooltip title='Delete'><Button danger icon={<DeleteOutlined />} onClick={() => handleDelete()}></Button></Tooltip>
    </Space>}
  >
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Text type='secondary'><HomeOutlined /></Text>
      <div>
        <Text>{address.line1}</Text>
        <br />
        <Text>{address.locality}, {address.region}, {address.country} {address.postalCode}</Text>
      </div>
      
      <Text type='secondary'><MailOutlined /></Text>
      <Text>{address.email}</Text>

      <Text type='secondary'><PhoneOutlined /></Text>
      <Text>{address.phoneNumber}</Text>
    </div>
  </Card>
}

const CardMethodCard = ({card}: {card: CardMethod}) => {
  return <Card>
    <div>
      <div style={{marginBlockEnd: '0.5em'}}>
        <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{card.cardNumber}</Title>
        <Text type='secondary' style={{fontSize: '0.75em'}}>{card.id}</Text>
      </div>
      {!!card.default || !!card.disabled ? <Space size='small'>
        {!!card.default ? <Tag color={blue.primary} icon={<StarOutlined />}>Default</Tag> : <></>}
        {!!card.disabled ? <Tag color={red.primary} icon={<ExclamationCircleOutlined />}>Disabled</Tag> : <></>}
      </Space> : <></>}
    </div>
    <Divider />
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Text type='secondary'><FieldTimeOutlined /></Text>
      <Text>{card.expirationMonth}/{card.expirationYear}</Text>

      <Text type='secondary'><UserOutlined /></Text>
      <Text>{card.nameOnCard}</Text>
    </div>
  </Card>
}

const NewCustomerCard = ({mid, onRefresh}: {mid: string, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createCustomer } = usePaylinkAPI()

  const handleSubmit = useCallback((request: CustomerRequest) => {
    setLoading(true)
    createCustomer(mid, {customer: request})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Customer'>
      <CustomerForm onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Customer</Title>
  </FancyCard>
}

const PaymentView = ({merchant}: {merchant: Merchant}) => {
  const { getPayment, getGateway, getCustomer, capturePayment, voidPayment, refundPayment } = usePaylinkAPI()

  const mid = merchant.id!
  const { pid } = useParams<{pid: string}>()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ payment, setPayment ] = useState<Payment | undefined>(undefined)
  const [ gateway, setGateway ] = useState<Gateway | undefined>(undefined)
  const [ customer, setCustomer ] = useState<Customer | undefined>(undefined)

  useEffect(() => {
    refresh()
  }, [merchant, pid])

  const refresh = useCallback(() => {
    setLoading(true)
    getPayment(merchant.id!, pid)
      .then(p => {
        setPayment(p.payment)
        return p.payment
      })
      .then(p => Promise.all([getGateway(merchant.id!, p.gatewayId), getCustomer(merchant.id!, p.customerId)]))
      .then(([g, c]) => {
        setGateway(g.gateway)
        setCustomer(c.customer)
        setLoading(false)
      })
      .catch(e => {
        console.error(`[PaymentView] Bad response received in refresh`)
        setPayment(undefined)
        setCustomer(undefined)
        setLoading(false)
      })
  }, [mid, pid])

  const doCapture = useCallback(() => {
    setLoading(true)
    message.info({content: 'Capturing payment...', key: 'capture'})
    capturePayment(mid, pid)
      .catch(e => undefined)
      .then(_ => {
        message.success({content: 'Payment captured successfully!', key: 'capture'})
      })
      .then(refresh)
  }, [mid, pid])

  const doVoid = useCallback(() => {
    setLoading(true)
    message.info({content: 'Voiding payment...', key: 'void'})
    voidPayment(mid, pid)
      .catch(e => undefined)
      .then(_ => {
        message.success({content: 'Payment voided successfully!', key: 'void'})
      })
      .then(refresh)
  }, [mid, pid])

  const doRefund = useCallback(() => {
    setLoading(true)
    message.info({content: 'Refunding payment...', key: 'refund'})
    refundPayment(mid, pid)
      .then(_ => {
        message.success({content: 'Payment refunded successfully!', key: 'refund'})
      })
      .catch(e => {
        message.error({content: 'Payment could not be refunded', key: 'refund'})
      })
      .then(refresh)
  }, [mid, pid])

  return <Spin spinning={loading}>
    {!!payment && <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Result
                title={<Title level={2}>{payment.id}</Title>}
                icon={<DollarOutlined />}
                extra={<Space size='middle'>
                  {payment.paymentStatus === PaymentStatus.AUTHORIZED
                    ? <Button type='primary' onClick={doCapture}>Capture</Button>
                    : <></>}
                  {payment.paymentStatus !== PaymentStatus.VOIDED
                    ? <Popconfirm onConfirm={doVoid} title='Are you sure you want to void this transaction?'>
                        <Button>Void</Button>
                      </Popconfirm>
                    : <></>}
                  {payment.paymentStatus === PaymentStatus.CAPTURED
                    ? <Popconfirm onConfirm={doRefund} title='Are you sure you want to refund this transaction?'>
                        <Button>Refund</Button>
                      </Popconfirm>
                    : <></>}
                </Space>}
              />
            </Col>

            <Col span={24}>
              <Card title='Details'>
                <Table
                  columns={[
                    {
                      key: 'key',
                      dataIndex: 'key',
                      width: '50%',
                      render: (value) => <Text strong>{value}</Text>
                    },
                    {
                      key: 'value',
                      dataIndex: 'value',
                      width: '50%',
                    }
                  ]}
                  showHeader={false}
                  pagination={false}
                  dataSource={[
                    ...(!!gateway ? [{key: 'Gateway', value: <Text>{gateway.name} <Text type='secondary'>({gateway.id})</Text></Text>}] : []),
                    ...(!!customer ? [{key: 'Customer', value: <Link to={`/pl/${mid}/customers/${customer.id}`}>{customer.firstName} {customer.lastName}</Link>}] : []),
                    {key: 'Subtotal', value: (payment.captureAmount / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
                    {key: 'Surcharge', value: ((payment.surchargeAmount || 0) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
                    {key: 'Total', value: ((payment.captureAmount + (payment.surchargeAmount || 0)) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
                    {key: 'Created At', value: humanize(payment.creationMoment)},
                    {key: 'Modified At', value: humanize(payment.modificationMoment)},
                    {key: 'Status', value: payment.paymentStatus},
                    {key: 'Type', value: payment.paymentType},
                  ]}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>}
  </Spin>
}

const CustomerTitle = ({ customer }: {customer: Customer}) => {
  const hasName = !!customer.firstName || !!customer.lastName
  const hasCompany = !!customer.companyName
  if (hasName) return <>
    <Title level={2} style={{marginBlockEnd: 0}}>{`${customer.firstName} ${customer.lastName}`}</Title>
    {hasCompany ? <Title level={4} style={{marginBlockStart: 0, fontWeight: 'normal'}} type='secondary'>{customer.companyName}</Title> : <></>}
  </>
  return <Title level={2} style={{marginBlockEnd: 0}}>{customer.companyName}</Title>
}

const CustomerView = ({merchant}: {merchant: Merchant}) => {
  const { getCustomer, getAddresses, getCards, getBankAccounts, updateCustomer } = usePaylinkAPI()

  const history = useHistory()

  const mid = merchant.id!
  const { cid } = useParams<{cid: string}>()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ customer, setCustomer ] = useState<Customer | undefined>(undefined)
  const [ addresses, setAddresses ] = useState<any[] | undefined>(undefined)
  const [ cards, setCards ] = useState<any[] | undefined>(undefined)
  const [ bankAccounts, setBankAccounts ] = useState<any[] | undefined>(undefined)

  const [ showForm, setShowForm ] = useState<boolean>(false)

  useEffect(() => {
    refresh()
  }, [merchant, cid])

  const refresh = useCallback(() => {
    setLoading(true)
    return Promise.all([getCustomer(mid, cid), getAddresses(mid, cid), getCards(mid, cid), getBankAccounts(mid, cid)])
      .then(([c, a, p, b]) => {
        setCustomer(c.customer)
        setAddresses(a.addresses)
        setCards(p.cards)
        setBankAccounts(b.bankAccounts)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setCustomer(undefined)
        setAddresses(undefined)
        setCards(undefined)
        setBankAccounts(undefined)
        setLoading(false)
      })
  }, [mid, cid])

  const handleUpdate = useCallback((values: CustomerRequest) => {
    if (!mid || !cid || !customer) return
    setLoading(true)
    message.info({content: <Text>Updating customer <Text strong>{getCustomerName(values as Customer)}</Text></Text>, key: 'updateCustomer'})
    updateCustomer(mid, cid, {customer: {...values, disabled: false}})
      .then(refresh)
      .then(_ => {
        setShowForm(false)
        setLoading(false)
        message.success({content: <Text>Customer <Text strong>{getCustomerName(values as Customer)}</Text> updated successfully!</Text>, key: 'updateCustomer'})
      })
      .catch(e => {
        setLoading(false)
        message.error({content: <Text>Customer <Text strong>{getCustomerName(customer)}</Text> could not be updated</Text>, key: 'updateCustomer'})
      })
  }, [mid, cid, customer])

  const handleDelete = useCallback(() => {
    if (!mid || !cid || !customer) return
    setLoading(true)
    message.info({content: <Text>Deleting customer <Text strong>{getCustomerName(customer)}</Text></Text>, key: 'deleteCustomer'})
    updateCustomer(mid, cid, {customer: {...customer, disabled: true}} as any)
      .then(_ => {
        setLoading(false)
        message.success({content: <Text>Customer <Text strong>{getCustomerName(customer)}</Text> deleted successfully!</Text>, key: 'deleteCustomer'})
        history.push(`/pl/${mid}/customers`)
      })
      .catch(e => {
        setLoading(false)
        message.error({content: <Text>Customer <Text strong>{getCustomerName(customer)}</Text> could not be deleted</Text>, key: 'deleteCustomer'})
      })
  }, [mid, cid, customer])

  return <Spin spinning={loading}>
    {!!customer && <>
      <br />
      <Row gutter={[32, 32]}>
        {showForm
          ? <>
              <Col span={24}>
                <Card title='Edit Customer'>
                  <CustomerForm
                    initialValues={customer as CustomerRequest}
                    onSubmit={(values) => handleUpdate(values)}
                    onCancel={() => setShowForm(false)}
                  />
                </Card>
              </Col>
            </>
          : <>
              <Col span={24}>
                <Card>
                  <Result
                    title={<>
                      <CustomerTitle customer={customer} />
                    </>}
                    icon={<UserOutlined />}
                    extra={<Space size='middle'>
                      <Button type="primary" ghost icon={<EditOutlined />} onClick={() => setShowForm(true)}>Edit</Button>
                      <Popconfirm
                        onConfirm={() => handleDelete()}
                        title={<Text>Are you sure you want to delete customer <Text strong>{getCustomerName(customer!)}</Text>?</Text>}
                      >
                        <Button danger ghost icon={<DeleteOutlined />}>Delete</Button>
                      </Popconfirm>
                    </Space>}
                  />
                </Card>
              </Col>

              <Col span={24}>
                <Card title='Details'>
                  <Table
                    showHeader={false}
                    pagination={false}
                    columns={[{key: 'key', dataIndex: 'key', width: '50%', render: value => <Text strong>{value}</Text>}, {key: 'value', dataIndex: 'value', width: '50%'}]}
                    dataSource={[
                      {key: 'Email', value: customer.email},
                      {key: 'Phone Number', value: customer.phoneNumber},
                      {key: 'Merchant Account Number', value: customer.merchantAccountNumber}
                    ]}
                  />
                </Card>
              </Col>
            </>}
        

        <Col span={24}>
          <Divider>Addresses</Divider>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <NewAddressCard mid={mid} cid={cid} onRefresh={refresh} />
            </Col>
            {!!addresses
              ? addresses.map(a => <Col xs={24} xl={12}>
                  <AddressCard merchant={merchant} address={a} key={a.id} onRefresh={refresh} />
                </Col>)
              : <></>}
          </Row>
        </Col>

        <Col span={24}>
          <Divider>Payment Methods</Divider>
          <Row gutter={[32, 32]}>
          {!!cards
            ? cards.map(c => <Col xs={24} xl={12}>
              <CardMethodCard card={c} key={c.id} />
            </Col>)
            : <></>}
          {!!bankAccounts
            ? bankAccounts.map(b => <Card key={b.id}>{JSON.stringify(b)}</Card>)
            : <></>
          }
          </Row>
        </Col>
      </Row>
    </>}
    <br />
  </Spin>
}

type InviteForm_Shape = {
  email: string,
  role: UserRole,
}

const InviteForm = ({ onSubmit, onCancel }: {onSubmit: (values: InviteForm_Shape) => void, onCancel: () => void}) => {
  const options = useMemo(() => {
    return [UserRole.PAYLINK_ADMIN, UserRole.PAYLINK_ACCOUNTING, UserRole.PAYLINK_PAYER]
      .map(role => ({label: <RoleTag role={role} />, key: role, value: role}))
  }, [])

  return <Form
    {...formLayout}
    onFinish={values => onSubmit(values)}
  >
    <Form.Item
      name='email'
      label="Email"
      rules={[{required: true, message: 'Please enter an email address'}, {type: 'email', message: 'Please enter a valid email address'}, maxLengthRule(DefaultEmailMaxLength)]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name='role'
      label='Role'
      rules={[{required: true, message: 'Please enter a valid role'}]}
    >
      <Select
        options={options}
      />
    </Form.Item>

    <Form.Item {...tailLayout}>
      <Space>
        <Button type="primary" htmlType="submit">Invite</Button>
        <Button type="default" htmlType="button" onClick={onCancel}>Cancel</Button>
      </Space>
    </Form.Item>
  </Form>
}

const NewInviteCard = ({merchant, onRefresh}: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const mid = merchant.id!

  const { sendInvite } = usePaylinkAPI()

  const handleSubmit = useCallback((values: InviteForm_Shape) => {
    setLoading(true)
    const request: InviteRequest = {
      merchantId: mid,
      email: values.email,
      roles: [values.role],
      origin: window.location.origin
    }
    message.info({ content: <Text>Sending invite to <Text strong>{values.email}</Text></Text>, key: 'inviteUser' })
    return sendInvite(mid, request)
      .then(onRefresh)
      .then(_ => {
        message.success({ content: <Text>Invite to <Text strong>{values.email}</Text> sent successfully</Text>, key: 'inviteUser' })
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        message.error({ content: <Text>Invite to <Text strong>{values.email}</Text> could not be sent</Text>, key: 'inviteUser' })
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Invite'>
      <InviteForm onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Invite</Title>
  </FancyCard>
}


enum UserStatus {
  ACTIVE = 'Active',
  INVITED = 'Invited',
}

const UsersTable = ({ merchant, onRefresh }: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const { getSearchProps } = useTableSearch<any>()
  const { deleteUser, deleteInvite } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)

  const mid = merchant.id!

  type UserRecord = User & {role: string, status: UserStatus.ACTIVE}
  type InviteRecord = Invite & {status: UserStatus.INVITED}
  type KeyedRecord = (UserRecord | InviteRecord) & {key: string}

  const deleteRecord = useCallback((record: KeyedRecord) => {
    if (record.status === UserStatus.ACTIVE) {
      setLoading(true)
      message.info({ content: <Text>Deleting user <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text></Text>, key: 'deleteRecord' })
      deleteUser(mid, record.user_id, record.role)
        .then(onRefresh)
        .then(_ => {
          message.success({ content: <Text>User <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text> was deleted successfully</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
        .catch(e => {
          message.error({ content: <Text>User <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text> could not be deleted</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
    } else if (record.status === UserStatus.INVITED) {
      setLoading(true)
      message.info({ content: <Text>Deleting invite to <Text strong>{record.email}</Text></Text>, key: 'deleteRecord' })
      deleteInvite(mid, record.token)
        .then(onRefresh)
        .then(_ => {
          message.success({ content: <Text>Invite to <Text strong>{record.email}</Text> was deleted successfully</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
        .catch(e => {
          message.error({ content: <Text>Invite to <Text strong>{record.email}</Text> could not be deleted</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
    }
  }, [merchant])

  const dataSource = useMemo(() => {
    const users = (merchant.users || []).map(u => ({...u, key: u.user_id, status: UserStatus.ACTIVE})) as KeyedRecord[]
    const invites = (merchant.invites || []).filter(i => i.active).map(i => ({...i, key: i.email, status: UserStatus.INVITED})) as KeyedRecord[]
    const data = [...users, ...invites]
    return data
  }, [merchant])

  return <Spin spinning={loading}>
    <Table
      columns={[
        {
          title: 'Name',
          key: 'name',
          render: (_, record) => {
            if (record.status === UserStatus.ACTIVE) return <div>
              <Text>{(record as User).name}</Text>
              <br />
              <Text type='secondary'>{record.email}</Text>
            </div>
            else return <Text type='secondary'>{record.email}</Text>
          },
          ...getSearchProps((value, record) => {
            const userId = (record.userId || '').toLowerCase()
            const name = (record.name || '').toLowerCase()
            const email = (record.email || '').toLowerCase()
            const searchValue = value.toString().toLowerCase()
            return [userId, name, email].some(v => v.includes(searchValue))
          })
        },
        {
          title: 'Status',
          key: 'status',
          render: (_, record) => {
            if (record.status === UserStatus.ACTIVE) return <Tag color='success'>Active</Tag>
            else if (record.status === UserStatus.INVITED) return <Tag color='warning'>Invited</Tag>
            return <></>
          },
          filters: [
            {
              value: UserStatus.ACTIVE,
              text: <Tag color='success'>Active</Tag>
            },
            {
              value: UserStatus.INVITED,
              text: <Tag color='warning'>Invited</Tag>
            }
          ],
          onFilter: (value, record) => record.status === value
        },
        {
          title: 'Role',
          key: 'role',
          render: (_, record) => record.status === UserStatus.ACTIVE ? <RoleTag role={record.role} /> : <Space size='small'>{Array.from(record.roles).map(role => <RoleTag key={role} role={role}/>)}</Space>
        },
        {
          key: 'actions',
          render: (_, record) => <div style={{textAlign: 'right'}}>
            <Popconfirm
              placement="left"
              title={record.status === UserStatus.ACTIVE ? <Text>Are you sure you want to delete user <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text>?</Text> : <Text>Are you sure you want to revoke invite to <Text strong>{record.email}</Text>?</Text>}
              onConfirm={() => deleteRecord(record)}
            >
            <Button ghost danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        }
      ]}
      dataSource={dataSource}
    />
  </Spin>
}

const UsersInner = ({ merchant, onRefresh }: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const mid = merchant.id!

  const [ loading, setLoading ] = useState<boolean>(false)

  const handleRefresh = useCallback(() => {
    setLoading(true)
    return onRefresh()
      .then(_ => setLoading(false))
  }, [onRefresh])

  if (!merchant) return <></>
  return <Spin spinning={loading}>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Users</Title>}
          icon={<UserOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewInviteCard merchant={merchant} onRefresh={handleRefresh} />
      </Col>

      <Col span={24}>
        <UsersTable merchant={merchant} onRefresh={handleRefresh} />
      </Col>
    </Row>
  </Spin>
}

const { Text } = Typography

const PaylinkMerchantSidebar = ({ merchant }: {merchant: Merchant}) => {
  const history = useHistory()
  const match = useRouteMatch()

  useEffect(() => {
    console.log(window.location.pathname)
  }, [window.location.pathname])

  const selectedMenuItems = useMemo<MenuKey[]>(() => {
    return [MenuKey.PAYMENTS, MenuKey.GATEWAYS, MenuKey.CUSTOMERS, MenuKey.USERS].filter(k => window.location.pathname.includes(k))
  }, [window.location.pathname])

  const menuItems: (ItemType)[] = [
    {key: MenuKey.PAYMENTS, label: <Text strong>Payments</Text>, icon: <DollarCircleOutlined />},
    {key: MenuKey.GATEWAYS, label: <Text strong>Gateways</Text>, icon: <BankOutlined />},
    {key: MenuKey.CUSTOMERS, label: <Text strong>Customers</Text>, icon: <ContactsOutlined />},
    {key: MenuKey.USERS, label: <Text strong>Users</Text>, icon: <UserOutlined />}
  ]

  if (!merchant) return <></>
  return <>
    <div style={{padding: '0.5em', display: 'flex', flexFlow: 'row', alignItems: 'center'}}>
      <Avatar size={48} shape='square' icon={<TeamOutlined />} />
      <div style={{marginInlineStart: '0.5em'}}>
        <Text strong style={{fontSize: '1em'}}>{getMerchantDisplayName(merchant)}</Text>
        <br />
        <Text type='secondary' style={{fontSize: '0.75em'}}>{merchant.id}</Text>
      </div>
    </div>
    <Divider style={{marginBlock: '0.5em'}} />
    <Menu
      mode='inline'
      items={menuItems}
      onClick={info => history.push(`/pl/${merchant.id!}/${info.key}`)}
      selectedKeys={selectedMenuItems}
    />

  </>
}

enum MenuKey {
  PAYMENTS = 'payments',
  GATEWAYS = 'gateways',
  CUSTOMERS = 'customers',
  USERS = 'users'
}

const PaylinkMerchantViewRoot = () => {
  const { mid } = useParams<{mid: string}>()
  return <Redirect to={`/pl/${mid}/payments`} />
}

export const PaylinkMerchantView = () => {
  const { mid } = useParams<{mid: string}>()

  const { getMerchant } = useMerchantAPI()

  const refresh = useCallback(() => {
    if (!mid) return Promise.resolve()
    return getMerchant(mid)
      .then(m => setMerchant(m.merchant))
      .catch(e => setMerchant(undefined))
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  const [ merchant, setMerchant ] = useState<Merchant | undefined>(undefined)

  if (!merchant) return <Loading />

  return <>
    <Layout style={{minHeight: '100%'}}>
      <Sider theme="light" width={360} style={{minHeight: '100%'}}>
        <PaylinkMerchantSidebar merchant={merchant} />
      </Sider>
      <Content className='main-content'>
        <Switch>
          <Route path='/pl/:mid' component={PaylinkMerchantViewRoot} exact />
          <Route path={`/pl/:mid/${MenuKey.PAYMENTS}`} component={() => <PaymentsInner merchant={merchant} />} exact />
          <Route path={`/pl/:mid/${MenuKey.PAYMENTS}/new`} component={() => <PaylinkVirtualTerminalView merchant={merchant} />} exact />
          <Route path={`/pl/:mid/${MenuKey.PAYMENTS}/:pid`} component={() => <PaymentView merchant={merchant} />} />
          <Route path={`/pl/:mid/${MenuKey.GATEWAYS}`} component={() => <GatewaysInner merchant={merchant} />} exact />
          <Route path={`/pl/:mid/${MenuKey.CUSTOMERS}`} component={() => <CustomersInner merchant={merchant} />} exact />
          <Route path={`/pl/:mid/${MenuKey.CUSTOMERS}/:cid`} component={() => <CustomerView merchant={merchant} />} />
          <Route path={`/pl/:mid/${MenuKey.USERS}`} component={() => <UsersInner merchant={merchant} onRefresh={refresh} />} exact />
        </Switch>
      </Content>
    </Layout>
  </>
} 